//Dependencies
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Trans } from "react-i18next";

//Utils
import { modalContent } from "./modalContent";
import { getAll } from "../utils/services";

export const modalRestaurantSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.restaurantSuccessful.content", {
    action,
    name,
  });

  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalRestaurantError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.restaurantError.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalAllergenSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.allergenSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalAllergenError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.allergenError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalTagSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.tagSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalTagError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.tagError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalUserSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.userSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalUserError = (action, name, errors, t) => {
  let content = document.createElement("span");
  let listErrors = "<ul>";
  Object.keys(errors).forEach((errorKey) => {
    listErrors += `<li>${errors[errorKey]}</li>`;
  });
  listErrors += "</ul>";
  content.innerHTML = `No se ha podido ${action} el usuario <strong>${name}</strong>, por lo siguiente ${listErrors} inténtelo de nuevo en unos minutos.`;
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalChangePasswordSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.changePasswordSuccessful");
  swal({
    title: t("modals.common.successTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalChangePasswordError = (error, t) => {
  let content = document.createElement("span");
  content.innerHTML = error.message;
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalCurrencySuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.currencySuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalCurrencyError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.currencyError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalCountrySuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.countrySuccessful.content", { action, name });
  swal({
    title: t("modals.common.successTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalCountryError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.countryError.content", { action, name });
  swal({
    title: "¡Ha ocurrido un error!",
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalCategorySuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.categorySuccessful.content", {
    categoryName: name,
    action,
  });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalCategoryError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.categoryError", { action, categoryName: name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalProductSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.productSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalProductError = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.productError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  }).then(() => cb());
};

export const modalToppingSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.toppingSuccessful.content", { action, name });

  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalToppingError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.toppingError.content", { action, name });

  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalAccompanimentSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.accompanimentSuccessful.content", {
    action,
    name,
  });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalAccompanimentError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.accompanimentError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalVariantSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.variantSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalVariantError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.variantError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalPlanSuccessful = (action, name, cb, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.planSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalPlanError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.planError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalMobileVersionSuccessful = (action, cb, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.mobileVersionSucessful.content", {
    action,
    name,
  });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalMobileVersionError = (action, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.mobileVersionError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalMenuSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.menuSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalMenuError = (action, name, t, errors) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.menuError.content", { action, name });
  if (errors) {
    content.innerHtml += "<br>";
    content.innerHtml += "<ul>";
    Object.keys(errors).forEach((error) => {
      content.innerHTML += `<li> ${errors[error]} </li>`;
    });
    content.innerHtml += "</ul>";
  }
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalModelSuccessful = (action, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.modelSuccessful.content", { action });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalModelError = (action, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.modelError.content", { action });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalBranchSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.branchSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalBranchError = (action, name, t, errors = []) => {
  let content = document.createElement("span");
  let listErrors = "<ul>";
  Object.keys(errors).forEach((errorKey) => {
    listErrors += `<li>${errors[errorKey]}</li>`;
  });
  listErrors += "</ul>";

  content.innerHTML = t("modals.branchError.content", {
    action,
    name,
    listErrors,
  });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalTranslationSuccessful = (action, name, t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.translationSuccessful.content", {
    action,
    name,
  });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalTranslationError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.translationError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalSuccessfulPayment = (t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.successfulPayment.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalErrorPayment = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.errorPayment.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalNotificationSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.notificationSuccessful.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalNotificationError = (t) => {
  let content = document.createElement("span");
  content.innerHTML = ``;
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalMenuClonedSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.menuClonedSuccessful.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalMenuClonedError = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.menuClonedError.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalMenuAsignedSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.menuAssignedSuccessful.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalMenuAsignedError = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.menuAssignedError.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalSuscriptionSuccessful = (t, cb) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.suscriptionSuccessful.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  }).then(() => cb());
};

export const modalSuscriptionError = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.suscriptionError.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalCancelSuscriptionSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.cancelSuscriptionSuccessful.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalCancelSuscriptionError = () => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.cancelSuscriptionError.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalEmailSentSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.emailSentSuccessful.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalEmailSentError = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.emailSentError.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalMailTemplateSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.mailTemplateSuccessful.content");
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalMailTemplateError = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.mailTemplateError.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalTableSuccessful = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.tableSuccessful.content", { action, name });
  swal({
    title: t("modals.common.successfulTitle"),
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalTableError = (action, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.tableError.content", { action, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalMultiTableSuccessful = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.multiTableSuccessful.content");
  swal({
    title: "¡Excelente!",
    content: content,
    icon: "success",
    dangerMode: true,
  });
};

export const modalMultiTableError = (t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.multiTableError.content");
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalEntityAlreadyExist = (entity, name, t) => {
  let content = document.createElement("span");
  content.innerHTML = t("modals.entityAlreadyExist.content", { entity, name });
  swal({
    title: t("modals.common.errorTitle"),
    content: content,
    icon: "error",
    dangerMode: true,
  });
};

export const modalDeleteEntity = (
  entity,
  { id, name, first_name, translations, plan_id },
  URL,
  actions,
) => {
  const content = modalContent[entity].delete;
  const entityName = name
    ? name
    : first_name
      ? first_name
      : translations?.length > 0
        ? translations[0].description
        : "";

  swal({
    title: content.title(entityName),
    text: content.text,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      if (entity === "plan_promotions") {
        const plan = {
          id: plan_id,
          plan_promotions_attributes: [{ id: id, _destroy: "1" }],
        };

        axios
          .put(URL, { plan })
          .then(() => actions.removePlanPromotion(id))
          .then(() => {
            swal(content.confirmText(entityName), {
              icon: "success",
              dangerMode: true,
            });
          })
          .catch(() =>
            swal({
              title: "Error",
              text: content.errorText(entityName),
              icon: "error",
              dangerMode: true,
            }),
          );
      } else {
        const BRANCH_ID = window.localStorage.getItem("branchId");
        axios
          .delete(URL, { params: { branch_id: BRANCH_ID } })
          .then(() => {
            switch (entity) {
              case "restaurants":
                actions.removeRestaurant(id);
                break;
              case "branches":
                actions.removeBranch(id);
                break;
              case "allergens":
                actions.removeAllergen(id);
                break;
              case "tags":
                actions.removeTag(id);
                break;
              case "users":
                actions.removeUser(id);
                break;
              case "currencies":
                actions.removeCurrency(id);
                break;
              case "countries":
                actions.removeCountry(id);
                break;
              case "categories":
                actions.removeCategory(id);
                break;
              case "menus":
                actions.removeMenu(id);
                break;
              case "products":
                actions.removeProduct(id);
                break;
              case "toppings":
                actions.removeTopping(id);
                break;
              case "accompaniments":
                actions.removeAccompaniment(id);
                break;
              case "variants":
                actions.removeVariant(id);
                break;
              case "plans":
                actions.removePlan(id);
                break;
              case "mobile_versions":
                actions.removeMobileVersion(id);
                break;
              case "tables":
                actions.removeTable(id);
                break;
            }
          })
          .then(() => {
            swal(content.confirmText(entityName), {
              icon: "success",
              dangerMode: true,
            });
          })
          .catch(() =>
            swal({
              title: "Error",
              text: content.errorText(entityName),
              icon: "error",
              dangerMode: true,
            }),
          );
      }
    }
  });
};

export const modalCloneEntity = async (selectedEntity, cloneFunction, t) => {
  const CLONE_PRODUCT = await Swal.fire({
    title: t("modals.productClone.title"),
    html: t("modals.productClone.content", { title: selectedEntity?.title }),
    confirmButtonText: t("modals.productClone.confirm"),
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      cloneFunction(selectedEntity);
    }
  });
  return CLONE_PRODUCT;
};

export const modalAssignEntity = async (
  entity,
  selectedEntity,
  URL,
  actions,
  branches,
  t,
) => {
  const SELECTED_BRANCHES = await Swal.fire({
    title: t("modals.branchAssign.title"),
    html: branches
      .map((branch) => {
        return `<div class="branches-cb__group">
          <input
          type="checkbox"
          id=${branch.id}
          value=${branch.name}
          class="branch-cb"
          name='branch-option'
          />
          <label for=${branch.id}>${branch.name}</label>
          </div>`;
      })
      .join(""),
    confirmButtonText: t("modals.branchAssign.confirm"),
    preConfirm: () => {
      const branches_cb = Swal.getPopup().querySelectorAll(".branch-cb");
      const SELECTED_BRANCHES = Array.from(branches_cb)
        .filter((cb) => cb.checked)
        .map((branch) => ({ id: branch.id, name: branch.name }));

      if (SELECTED_BRANCHES.length === 0) {
        Swal.showValidationMessage(t("modals.branchAssign.validate"));
      }

      return SELECTED_BRANCHES;
    },
  });

  if (SELECTED_BRANCHES.value) {
    const branchIds = SELECTED_BRANCHES.value.map((v) => Number(v.id));
    axios
      .post(URL, {
        ...selectedEntity,
        type: "menu",
        branches_id: branchIds,
      })
      .then(() => {
        actions.updateMenu({
          ...selectedEntity,
          branch_ids: selectedEntity.branch_ids.concat(branchIds),
        });
        modalMenuAsignedSuccessful(t);
      })
      .catch(() => modalMenuAsignedError(t));
  }
};

export const modalCancelTransaction = (
  entity,
  selectedEntity,
  URL,
  actions,
  loggedUser,
) => {
  const content = modalContent[entity].cancel;

  swal({
    title: content.title,
    text: content.text,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willCancel) => {
    if (willCancel) {
      axios
        .put(URL, { ...selectedEntity, state: "waived" })
        .then(() =>
          axios
            .get(
              getAll.payment_transactions(
                loggedUser.restaurant_id,
                loggedUser.branch_id,
              ),
            )
            .then((res) => {
              const { payment_transactions } = res.data;
              actions.clearPaymentTransactions();
              actions.setPaymentTransactions(payment_transactions);
            }),
        )
        .then(() => {
          swal(content.confirmText, {
            icon: "success",
            dangerMode: true,
          });
        })
        .catch(() =>
          swal({
            title: "Error",
            text: content.errorText,
            icon: "error",
            dangerMode: true,
          }),
        );
    }
  });
};
