//Dependencies
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Assets
import unavailableImage from "../images/unavailable-image.jpg";
import "../stylesheets/imageUploader.css";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import ErrorIcon from "@material-ui/icons/Error";
import { Tooltip, IconButton } from "@material-ui/core";

const ImageUploader = ({
  inputName,
  image,
  handleSetValue,
  setCategoryImage = null,
  readOnly = false,
}) => {
  const [entityImage, setEntityImage] = useState(unavailableImage);
  const [oversize, setOversize] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!!image) setEntityImage(image);
  }, [image]);

  const handleImage = (e) => {
    const reader = new FileReader();
    const size = e.target.files[0].size;

    if (size > 700000) {
      setOversize(true);
    } else {
      setOversize(false);
      reader.onload = () => {
        if (reader.readyState === 2) {
          setEntityImage(reader.result);
          handleSetValue(e.target.name, reader.result);

          if (!!setCategoryImage) {
            setCategoryImage(reader.result);
          }
        }
      };

      try {
        reader.readAsDataURL(e.target.files[0]);
      } catch (err) {
        console.log(err)
      }
    }
  };

  const handleRemoveImage = () => {
    if (!!setCategoryImage) {
      setCategoryImage(null);
    }

    const inputFile = document.querySelector(`#upload-${inputName}`);
    inputFile.value = null;

    setEntityImage(unavailableImage);
    handleSetValue(inputName, "");
  };

  return (
    <section className="entity-previewimage-container">
      {oversize && (
        <Tooltip
          title="Máximo tamaño permitido 700KB"
          className="button-remove-previewimage"
        >
          <ErrorIcon style={{ color: "red" }} />
        </Tooltip>
      )}
      {!readOnly && entityImage !== unavailableImage && (
        <span
          className="button-remove-previewimage"
          onClick={handleRemoveImage}
        >
          <RemoveIcon />
        </span>
      )}
      <img
        src={entityImage}
        alt="Entity Image"
        id={`entity-${inputName}`}
        className="entity-previewimage"
      />
      <input
        type="file"
        id={`upload-${inputName}`}
        name={inputName}
        accept="image/*"
        onChange={handleImage}
        ref={inputRef}
      />
      {!readOnly && (
        <section
          className="upload-previewimage-button"
          onClick={() => {
            inputRef.current.click();
          }}
        >
          AÑADIR IMAGEN
        </section>
      )}
    </section>
  );
};

export default ImageUploader;
