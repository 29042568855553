const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_MENU":
      return {
        ...state,
        activeMenu: action.payload,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_CURRENT_SECTION":
      return {
        ...state,
        currentSection: action.payload,
      };
    case "SET_RESTAURANTS":
      return {
        ...state,
        restaurants: [...new Set([...state.restaurants, ...action.payload])],
        filteredItems: [...new Set([...state.restaurants, ...action.payload])],
      };
    case "UPDATE_RESTAURANT":
      const restIndex = state.filteredItems.findIndex(
        (rest) => rest.id === action.payload.id,
      );
      let restaurantsUpdated = [...state.filteredItems];
      restaurantsUpdated[restIndex] = action.payload;

      return {
        ...state,
        restaurants: restaurantsUpdated,
        filteredItems: restaurantsUpdated,
      };
    case "REMOVE_RESTAURANT":
      return {
        ...state,
        restaurants: state.restaurants.filter(
          (rest) => rest.id !== action.payload,
        ),
        filteredItems: state.restaurants.filter(
          (rest) => rest.id !== action.payload,
        ),
      };
    case "CLEAR_RESTAURANTS":
      return {
        ...state,
        restaurants: [],
      };
    case "SET_BRANCHES":
      return {
        ...state,
        branches: [...new Set([...state.branches, ...action.payload])],
        filteredItems: [...new Set([...state.branches, ...action.payload])],
      };
    case "UPDATE_BRANCH":
      const branchIndex = state.filteredItems.findIndex(
        (branch) => branch.id === action.payload.id,
      );
      let branchesUpdated = [...state.filteredItems];
      branchesUpdated[branchIndex] = action.payload;

      return {
        ...state,
        branches: branchesUpdated,
        filteredItems: branchesUpdated,
      };
    case "REMOVE_BRANCH":
      return {
        ...state,
        branches: state.branches.filter((rest) => rest.id !== action.payload),
        filteredItems: state.branches.filter(
          (rest) => rest.id !== action.payload,
        ),
      };
    case "CLEAR_BRANCHES":
      return {
        ...state,
        branches: [],
      };
    case "SET_ONLY_PRODUCTS":
      return {
        ...state,
        products: [...action.payload],
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        products: [...new Set([...state.products, ...action.payload])],
        filteredItems: [...new Set([...state.products, ...action.payload])],
      };
    case "UPDATE_PRODUCT":
      const productIndex = state.filteredItems.findIndex(
        (product) => product.id === action.payload.id,
      );
      let productsUpdated = [...state.filteredItems];
      productsUpdated[productIndex] = action.payload;

      return {
        ...state,
        products: productsUpdated,
        filteredItems: productsUpdated,
      };
    case "REMOVE_PRODUCT":
      return {
        ...state,
        products: state.products.filter((rest) => rest.id !== action.payload),
        filteredItems: state.products.filter(
          (rest) => rest.id !== action.payload,
        ),
      };
    case "CLEAR_PRODUCTS":
      return {
        ...state,
        products: [],
      };
    case "SET_ONLY_ALLERGENS":
      return {
        ...state,
        allergens: [...action.payload],
      };
    case "SET_ALLERGENS":
      return {
        ...state,
        allergens: [...new Set([...state.allergens, ...action.payload])],
        filteredItems: [...new Set([...state.allergens, ...action.payload])],
      };
    case "UPDATE_ALLERGEN":
      const allergenIndex = state.filteredItems.findIndex(
        (allergen) => allergen.id === action.payload.id,
      );
      let allergensUpdated = [...state.filteredItems];
      allergensUpdated[allergenIndex] = action.payload;

      return {
        ...state,
        allergens: allergensUpdated,
        filteredItems: allergensUpdated,
      };
    case "REMOVE_ALLERGEN":
      return {
        ...state,
        allergens: state.allergens.filter((rest) => rest.id !== action.payload),
        filteredItems: state.allergens.filter(
          (rest) => rest.id !== action.payload,
        ),
      };
    case "CLEAR_ALLERGENS":
      return {
        ...state,
        allergens: [],
      };
    case "SET_TAGS":
      return {
        ...state,
        tags: [...new Set([...state.tags, ...action.payload])],
        filteredItems: [...new Set([...state.tags, ...action.payload])],
      };
    case "UPDATE_TAG":
      const tagIndex = state.filteredItems.findIndex(
        (tag) => tag.id === action.payload.id,
      );
      let tagsUpdated = [...state.filteredItems];
      tagsUpdated[tagIndex] = action.payload;

      return {
        ...state,
        tags: tagsUpdated,
        filteredItems: tagsUpdated,
      };
    case "REMOVE_TAG":
      return {
        ...state,
        tags: state.tags.filter((rest) => rest.id !== action.payload),
        filteredItems: state.tags.filter((rest) => rest.id !== action.payload),
      };
    case "CLEAR_TAGS":
      return {
        ...state,
        tags: [],
      };
    case "SET_PAYMENT_METHODS":
      return {
        ...state,
        payment_methods: [
          ...new Set([...state.payment_methods, ...action.payload]),
        ],
        filteredItems: [
          ...new Set([...state.payment_methods, ...action.payload]),
        ],
      };
    case "CLEAR_PAYMENT_METHODS":
      return {
        ...state,
        payment_methods: [],
      };
    case "SET_SHIPPING_METHODS":
      return {
        ...state,
        delivery_methods: [
          ...new Set([...state.delivery_methods, ...action.payload]),
        ],
        filteredItems: [
          ...new Set([...state.delivery_methods, ...action.payload]),
        ],
      };
    case "CLEAR_SHIPPING_METHODS":
      return {
        ...state,
        delivery_methods: [],
      };
    case "SET_PLANS":
      return {
        ...state,
        plans: [...new Set([...state.plans, ...action.payload])],
        filteredItems: [...new Set([...state.plans, ...action.payload])],
      };
    case "UPDATE_PLAN":
      const planIndex = state.filteredItems.findIndex(
        (plan) => plan.id === action.payload.id,
      );
      let plansUpdated = [...state.filteredItems];
      plansUpdated[planIndex] = action.payload;

      return {
        ...state,
        plans: plansUpdated,
        filteredItems: plansUpdated,
      };
    case "REMOVE_PLAN":
      return {
        ...state,
        plans: state.plans.filter((plan) => plan.id !== action.payload),
        filteredItems: state.plans.filter((plan) => plan.id !== action.payload),
      };
    case "CLEAR_PLANS":
      return {
        ...state,
        plans: [],
      };
    case "SET_PLAN_PROMOTIONS":
      return {
        ...state,
        plan_promotions: [
          ...new Set([...state.plan_promotions, ...action.payload]),
        ],
        filteredItems: [
          ...new Set([...state.plan_promotions, ...action.payload]),
        ],
      };
    case "UPDATE_PLAN_PROMOTIONS":
      const planPromotionIndex = state.filteredItems.findIndex(
        (pp) => pp.id === action.payload.id,
      );
      let planPromotionsUpdated = [...state.filteredItems];
      planPromotionsUpdated[planPromotionIndex] = action.payload;

      return {
        ...state,
        plan_promotions: planPromotionsUpdated,
        filteredItems: planPromotionsUpdated,
      };
    case "REMOVE_PLAN_PROMOTION":
      return {
        ...state,
        plan_promotions: state.plan_promotions.filter(
          (pp) => pp.id !== action.payload,
        ),
        filteredItems: state.plan_promotions.filter(
          (pp) => pp.id !== action.payload,
        ),
      };
    case "CLEAR_PLAN_PROMOTIONS":
      return {
        ...state,
        plan_promotions: [],
      };
    case "SET_MOBILE_VERSIONS":
      return {
        ...state,
        mobileVersions: [
          ...new Set([...state.mobileVersions, ...action.payload]),
        ],
        filteredItems: [
          ...new Set([...state.mobileVersions, ...action.payload]),
        ],
      };
    case "UPDATE_MOBILE_VERSION":
      const mobileVersionIndex = state.filteredItems.findIndex(
        (mobileVersion) => mobileVersion.id === action.payload.id,
      );
      let mobileVersionsUpdated = [...state.filteredItems];
      mobileVersionsUpdated[mobileVersionIndex] = action.payload;

      return {
        ...state,
        mobileVersions: mobileVersionsUpdated,
        filteredItems: mobileVersionsUpdated,
      };
    case "REMOVE_MOBILE_VERSION":
      return {
        ...state,
        mobileVersions: state.mobileVersions.filter(
          (mobileVersion) => mobileVersion.id !== action.payload,
        ),
        filteredItems: state.mobileVersions.filter(
          (mobileVersion) => mobileVersion.id !== action.payload,
        ),
      };
    case "CLEAR_MOBILE_VERSIONS":
      return {
        ...state,
        mobileVersions: [],
      };
    case "SET_USERS":
      return {
        ...state,
        users: [...new Set([...state.users, ...action.payload])],
        filteredItems: [...new Set([...state.users, ...action.payload])],
      };
    case "UPDATE_USER":
      const userIndex = state.filteredItems.findIndex(
        (user) => user.id === action.payload.id,
      );
      let usersUpdated = [...state.filteredItems];
      usersUpdated[userIndex] = action.payload;

      return {
        ...state,
        users: usersUpdated,
        filteredItems: usersUpdated,
      };
    case "REMOVE_USER":
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
        filteredItems: state.users.filter((user) => user.id !== action.payload),
      };
    case "CLEAR_USERS":
      return {
        ...state,
        users: [],
      };
    case "SET_CURRENCIES":
      return {
        ...state,
        currencies: [...new Set([...state.currencies, ...action.payload])],
        filteredItems: [...new Set([...state.currencies, ...action.payload])],
      };
    case "UPDATE_CURRENCY":
      const currencyIndex = state.filteredItems.findIndex(
        (currency) => currency.id === action.payload.id,
      );
      let currenciesUpdated = [...state.filteredItems];
      currenciesUpdated[currencyIndex] = action.payload;

      return {
        ...state,
        currencies: currenciesUpdated,
        filteredItems: currenciesUpdated,
      };
    case "REMOVE_CURRENCY":
      return {
        ...state,
        currencies: state.currencies.filter(
          (rest) => rest.id !== action.payload,
        ),
        filteredItems: state.currencies.filter(
          (rest) => rest.id !== action.payload,
        ),
      };
    case "CLEAR_CURRENCIES":
      return {
        ...state,
        currencies: [],
      };
    case "SET_COUNTRIES":
      return {
        ...state,
        countries: [...new Set([...state.countries, ...action.payload])],
        filteredItems: [...new Set([...state.countries, ...action.payload])],
      };
    case "UPDATE_COUNTRIES":
      const countryIndex = state.filteredItems.findIndex(
        (country) => country.id === action.payload.id,
      );
      let countriesUpdated = [...state.filteredItems];
      countriesUpdated[countryIndex] = action.payload;

      return {
        ...state,
        countries: countriesUpdated,
        filteredItems: countriesUpdated,
      };
    case "REMOVE_COUNTRY":
      return {
        ...state,
        countries: state.countries.filter((rest) => rest.id !== action.payload),
        filteredItems: state.countries.filter(
          (rest) => rest.id !== action.payload,
        ),
      };
    case "CLEAR_COUNTRIES":
      return {
        ...state,
        countries: [],
      };
    case "SET_MENUS":
      return {
        ...state,
        menus: [...new Set([...state.menus, ...action.payload])],
        filteredItems: [...new Set([...state.menus, ...action.payload])],
      };
    case "UPDATE_MENU":
      const menuIndex = state.filteredItems.findIndex(
        (menu) => menu.id === action.payload.id,
      );
      let menusUpdated = [...state.filteredItems];
      menusUpdated[menuIndex] = action.payload;

      return {
        ...state,
        menus: menusUpdated,
        filteredItems: menusUpdated,
      };
    case "REMOVE_MENU":
      return {
        ...state,
        menus: state.menus.filter((menu) => menu.id !== action.payload),
        filteredItems: state.menus.filter((menu) => menu.id !== action.payload),
      };
    case "CLEAR_MENUS":
      return {
        ...state,
        menus: [],
      };
    case "SET_ONLY_CATEGORIES":
      return {
        ...state,
        categories: [...new Set([...state.categories, ...action.payload])],
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: [...new Set([...state.categories, ...action.payload])],
        filteredItems: [...new Set([...state.categories, ...action.payload])],
      };
    case "UPDATE_CATEGORY":
      const categoryIndex = state.filteredItems.findIndex(
        (cat) => cat.id === action.payload.id,
      );
      let categoriesUpdated = [...state.filteredItems];
      categoriesUpdated[categoryIndex] = action.payload;

      return {
        ...state,
        categories: categoriesUpdated,
        filteredItems: categoriesUpdated,
      };
    case "REMOVE_CATEGORY":
      return {
        ...state,
        categories: state.categories.filter((cat) => cat.id !== action.payload),
        filteredItems: state.categories.filter(
          (cat) => cat.id !== action.payload,
        ),
      };
    case "CLEAR_CATEGORIES":
      return {
        ...state,
        categories: [],
      };
    case "SET_ONLY_TOPPINGS":
      return {
        ...state,
        toppings: [...action.payload],
      };
    case "SET_TOPPINGS":
      return {
        ...state,
        toppings: [...new Set([...state.toppings, ...action.payload])],
        filteredItems: [...new Set([...state.toppings, ...action.payload])],
      };
    case "UPDATE_TOPPING":
      const toppingIndex = state.filteredItems.findIndex(
        (topping) => topping.id === action.payload.id,
      );
      let toppingsUpdated = [...state.filteredItems];
      toppingsUpdated[toppingIndex] = action.payload;

      return {
        ...state,
        toppings: toppingsUpdated,
        filteredItems: toppingsUpdated,
      };
    case "REMOVE_TOPPING":
      return {
        ...state,
        toppings: state.toppings.filter((top) => top.id !== action.payload),
        filteredItems: state.toppings.filter(
          (top) => top.id !== action.payload,
        ),
      };
    case "CLEAR_TOPPINGS":
      return {
        ...state,
        toppings: [],
      };
    case "SET_ONLY_ACCOMPANIMENTS":
      return {
        ...state,
        accompaniments: [...action.payload],
      };
    case "SET_ACCOMPANIMENTS":
      return {
        ...state,
        accompaniments: [
          ...new Set([...state.accompaniments, ...action.payload]),
        ],
        filteredItems: [
          ...new Set([...state.accompaniments, ...action.payload]),
        ],
      };
    case "UPDATE_ACCOMPANIMENT":
      const accompanimentIndex = state.filteredItems.findIndex(
        (accompaniment) => accompaniment.id === action.payload.id,
      );
      let accompanimentsUpdated = [...state.filteredItems];
      accompanimentsUpdated[accompanimentIndex] = action.payload;

      return {
        ...state,
        accompaniments: accompanimentsUpdated,
        filteredItems: accompanimentsUpdated,
      };
    case "REMOVE_ACCOMPANIMENT":
      return {
        ...state,
        accompaniments: state.accompaniments.filter(
          (top) => top.id !== action.payload,
        ),
        filteredItems: state.accompaniments.filter(
          (rest) => rest.id !== action.payload,
        ),
      };
    case "CLEAR_ACCOMPANIMENTS":
      return {
        ...state,
        accompaniments: [],
      };
    case "SET_ONLY_VARIANTS":
      return {
        ...state,
        variants: [...action.payload],
      };
    case "SET_VARIANTS":
      return {
        ...state,
        variants: [...new Set([...state.variants, ...action.payload])],
        filteredItems: [...new Set([...state.variants, ...action.payload])],
      };
    case "UPDATE_VARIANT":
      const variantIndex = state.filteredItems.findIndex(
        (variant) => variant.id === action.payload.id,
      );
      let variantsUpdated = [...state.filteredItems];
      variantsUpdated[variantIndex] = action.payload;

      return {
        ...state,
        variants: variantsUpdated,
        filteredItems: variantsUpdated,
      };
    case "REMOVE_VARIANT":
      return {
        ...state,
        variants: state.variants.filter(
          (variant) => variant.id !== action.payload,
        ),
        filteredItems: state.variants.filter(
          (variant) => variant.id !== action.payload,
        ),
      };
    case "CLEAR_VARIANTS":
      return {
        ...state,
        variants: [],
      };
    case "SET_SCHEDULE":
      return {
        ...state,
        schedules: [...new Set([...state.schedules, ...action.payload])],
      };
    case "UPDATE_SCHEDULE":
      const scheduleIndex = state.schedules.findIndex(
        (schedule) => schedule.id === action.payload.id,
      );
      let schedulesUpdated = [...state.schedules];
      schedulesUpdated[scheduleIndex] = action.payload;

      return {
        ...state,
        schedules: schedulesUpdated,
      };
    case "REMOVE_SCHEDULE":
      const scheduleToRemoveIndex = action.payload;
      let updatedSchedules = [...state.schedules];

      if (!!updatedSchedules[scheduleToRemoveIndex].id) {
        updatedSchedules[scheduleToRemoveIndex] = {
          id: updatedSchedules[scheduleToRemoveIndex].id,
          _destroy: "1",
        };
      } else {
        updatedSchedules.splice(scheduleToRemoveIndex, 1);
      }

      return {
        ...state,
        schedules: updatedSchedules,
      };

    case "CLEAR_SCHEDULES":
      return {
        ...state,
        schedules: [],
      };
    case "ADD_ENTITY_ITEM":
      return {
        ...state,
        entityItems: [...new Set([...state.entityItems, ...action.payload])],
      };
    case "UPDATE_ENTITY_ITEM":
      const entityItemIndex = state.entityItems.findIndex(
        (i) => i === action.payload,
      );
      let entityItemsUpdated = [...state.entityItems];
      entityItemsUpdated[entityItemIndex] = action.payload;

      return {
        ...state,
        entityItems: entityItemsUpdated,
      };
    case "REMOVE_ENTITY_ITEM":
      const entityItemToRemoveIndex = state.entityItems.findIndex(
        (i) => i === action.payload,
      );
      let updatedEntityItems = [...state.entityItems];

      if (!!action.payload.id) {
        updatedEntityItems[entityItemToRemoveIndex] = {
          id: action.payload.id,
          _destroy: "1",
        };
      } else {
        updatedEntityItems = updatedEntityItems.filter(
          (i) => i !== action.payload,
        );
      }

      return {
        ...state,
        entityItems: updatedEntityItems,
      };
    case "CLEAR_ENTITY_ITEMS":
      return {
        ...state,
        entityItems: [],
      };
    case "ADD_PLAN_COUNTRY":
      return {
        ...state,
        planCountries: [
          ...new Set([...state.planCountries, ...action.payload]),
        ],
      };
    case "UPDATE_PLAN_COUNTRY":
      const planCountryIndex = state.planCountries.findIndex(
        (i) => i === action.payload,
      );
      let planCountriesUpdated = [...state.planCountries];
      planCountriesUpdated[planCountryIndex] = action.payload;

      return {
        ...state,
        planCountries: planCountriesUpdated,
      };
    case "REMOVE_PLAN_COUNTRY":
      const planCountryToRemoveIndex = state.planCountries.findIndex(
        (i) => i === action.payload,
      );
      let updatedPlanCountries = [...state.planCountries];

      if (!!action.payload.id) {
        updatedPlanCountries[planCountryToRemoveIndex] = {
          id: action.payload.id,
          _destroy: "1",
        };
      } else {
        updatedPlanCountries = updatedPlanCountries.filter(
          (i) => i !== action.payload,
        );
      }

      return {
        ...state,
        planCountries: updatedPlanCountries,
      };
    case "CLEAR_PLAN_COUNTRIES":
      return {
        ...state,
        planCountries: [],
      };
    case "ADD_MENU_CATEGORY":
      return {
        ...state,
        menuCategories: [
          ...new Set([...state.menuCategories, ...action.payload]),
        ],
      };
    case "UPDATE_MENU_CATEGORY":
      const menuCategoryIndex = state.menuCategories.findIndex(
        (menuCategory) =>
          menuCategory.category_id === action.payload.category_id,
      );
      let menuCategoriesUpdated = [...state.menuCategories];
      menuCategoriesUpdated[menuCategoryIndex] = action.payload;

      return {
        ...state,
        menuCategories: menuCategoriesUpdated,
      };
    case "REMOVE_MENU_CATEGORY":
      const menuCategoryToRemoveIndex = state.menuCategories.findIndex(
        (i) => i === action.payload,
      );
      let updatedMenuCategory = [...state.menuCategories];

      if (!!action.payload.id) {
        updatedMenuCategory[menuCategoryToRemoveIndex] = {
          id: action.payload.id,
          _destroy: "1",
        };
      } else {
        updatedMenuCategory = updatedMenuCategory.filter(
          (i) => i !== action.payload,
        );
      }

      return {
        ...state,
        menuCategoryDishes: state.menuCategoryDishes.filter(
          (dish) => dish.category_id !== action.payload.category_id,
        ),
        menuCategories: updatedMenuCategory,
      };
    case "CLEAR_MENU_CATEGORIES":
      return {
        ...state,
        menuCategories: [],
      };
    case "ADD_MENU_CATEGORY_PRODUCT":
      const { restaurantId, categoryId, selectedProduct, branchId } =
        action.payload;
      const newMenuCategoryDishes = {
        image: selectedProduct.image,
        id: selectedProduct.id,
        branch_id: branchId,
        restaurant_id: restaurantId,
        category_id: categoryId,
        product_id: selectedProduct.product_id,
        title: selectedProduct.title,
        description: selectedProduct.description,
        price: selectedProduct.price,
        discount_ratio: selectedProduct.discount_ratio,
        discount_value: selectedProduct.discount_value,
        dynamic_link: selectedProduct.dynamic_link,
        is_visible: selectedProduct.is_visible,
        can_share: selectedProduct.can_share,
        slot: state.menuCategoryDishes.filter(
          ({ category_id }) => category_id === categoryId,
        ).length,
        dish_allergens_attributes: selectedProduct.allergens?.map(
          ({ id, allergen }) => {
            if (allergen) {
              return {
                id: id,
                allergen_id: allergen.id,
              };
            }
          },
        ),
        dish_toppings_attributes: selectedProduct.toppings?.map(
          ({ id, topping }) => {
            if (topping) {
              return {
                id: id,
                topping_id: topping.id,
              };
            }
          },
        ),
        dish_variants_attributes: selectedProduct.variants?.map(
          ({ id, variant }) => {
            if (variant) {
              return {
                id: id,
                variant_id: variant.id,
              };
            }
          },
        ),
        dish_accompaniments_attributes: selectedProduct.accompaniments?.map(
          ({ id, accompaniment }) => {
            if (accompaniment) {
              return {
                id: id,
                accompaniment_id: accompaniment.id,
              };
            }
          },
        ),
      };

      return {
        ...state,
        menuCategoryDishes: [
          ...new Set([...state.menuCategoryDishes, newMenuCategoryDishes]),
        ],
      };
    case "ADD_MENU_CATEGORY_PRODUCTS":
      const { restaurantId2, branchId2, categoryId2, selectedProducts } =
        action.payload;
      const newDishes = [];
      selectedProducts.forEach((selectedProduct) => {
        const newMenuCategoryDishes = {
          image: selectedProduct.image,
          id: selectedProduct.id,
          branch_id: branchId2,
          restaurant_id: restaurantId2,
          category_id: categoryId2,
          product_id: selectedProduct.product_id,
          title: selectedProduct.title,
          description: selectedProduct.description,
          price: selectedProduct.price,
          discount_ratio: selectedProduct.discount_ratio,
          discount_value: selectedProduct.discount_value,
          dynamic_link: selectedProduct.dynamic_link,
          is_visible: selectedProduct.is_visible,
          can_share: selectedProduct.can_share,
          slot: selectedProduct.slot,
          dish_allergens_attributes: selectedProduct.allergens?.map(
            ({ id, allergen }) => {
              if (allergen) {
                return {
                  id: id,
                  allergen_id: allergen.id,
                };
              }
            },
          ),
          dish_toppings_attributes: selectedProduct.toppings?.map(
            ({ id, topping }) => {
              if (topping) {
                return {
                  id: id,
                  topping_id: topping.id,
                };
              }
            },
          ),
          dish_variants_attributes: selectedProduct.variants?.map(
            ({ id, variant }) => {
              if (variant) {
                return {
                  id: id,
                  variant_id: variant.id,
                };
              }
            },
          ),
          dish_accompaniments_attributes: selectedProduct.accompaniments?.map(
            ({ id, accompaniment }) => {
              if (accompaniment) {
                return {
                  id: id,
                  accompaniment_id: accompaniment.id,
                };
              }
            },
          ),
        };
        newDishes.push(newMenuCategoryDishes);
      });
      return {
        ...state,
        menuCategoryDishes: [
          ...new Set([...state.menuCategoryDishes, ...newDishes]),
        ],
      };
    case "REMOVE_MENU_CATEGORY_PRODUCT":
      const menuCategoryProdToRemoveIndex = state.menuCategoryDishes.findIndex(
        (i) => i === action.payload,
      );
      let updatedMenuCategoryDish = [...state.menuCategoryDishes];

      if (!!action.payload.id) {
        updatedMenuCategoryDish[menuCategoryProdToRemoveIndex] = {
          id: action.payload.id,
          _destroy: "1",
        };
      } else {
        updatedMenuCategoryDish = updatedMenuCategoryDish.filter(
          (i) => i !== action.payload,
        );
      }

      return {
        ...state,
        menuCategoryDishes: updatedMenuCategoryDish,
      };
    case "REORDER_MENU_CATEGORY_PRODUCT":
      const { category_Id, reorderedDishes } = action.payload;

      const otherCategoryDishes = state.menuCategoryDishes.filter(
        ({ category_id }) => category_id != category_Id,
      );

      return {
        ...state,
        menuCategoryDishes: [...otherCategoryDishes, ...reorderedDishes],
      };
    case "UPDATE_MENU_CATEGORY_PRODUCT":
      const menuCategoryDishIndex = state.menuCategoryDishes.findIndex(
        ({ category_id, product_id }) =>
          product_id == action.payload.product_id &&
          category_id == action.payload.category_id,
      );
      let menuCategoryDishesUpdated = [...state.menuCategoryDishes];
      menuCategoryDishesUpdated[menuCategoryDishIndex] = action.payload;

      return {
        ...state,
        menuCategoryDishes: menuCategoryDishesUpdated,
      };
    case "UPDATE_MENU_CATEGORY_PRODUCTS":
      const { products } = action.payload;
      const newMenuCategoryDishes2 = [...state.menuCategoryDishes];
      products.forEach((p) => {
        const productIndex = state.menuCategoryDishes.findIndex(
          ({ category_id, product_id }) =>
            p.category_id == category_id && p.product_id == product_id,
        );
        if (productIndex !== -1) {
          newMenuCategoryDishes2[productIndex] = p;
        }
      });
      return {
        ...state,
        menuCategoryDishes: newMenuCategoryDishes2,
      };
    case "CLEAR_MENU_CATEGORY_PRODUCT":
      if (!action.payload) {
        return {
          ...state,
          menuCategoryDishes: [],
        };
      }

      const menuCaProdUpdated = state.menuCategoryDishes.filter(
        ({ category_id }) => category_id != action.payload,
      );

      return {
        ...state,
        menuCategoryDishes: menuCaProdUpdated,
      };
    case "SET_SUGGESTIONS":
      return {
        ...state,
        suggestions: [...new Set([...state.suggestions, ...action.payload])],
        filteredItems: [...new Set([...state.suggestions, ...action.payload])],
      };
    case "CLEAR_SUGGESTIONS":
      return {
        ...state,
        suggestions: [],
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: [
          ...new Set([...state.notifications, ...action.payload]),
        ],
        filteredItems: [
          ...new Set([...state.notifications, ...action.payload]),
        ],
      };
    case "CLEAR_NOTIFICATIONS":
      return {
        ...state,
        notifications: [],
      };
    case "SET_MODELS":
      return {
        ...state,
        models: [...new Set([...state.models, ...action.payload])],
        filteredItems: [...new Set([...state.models, ...action.payload])],
      };
    case "CLEAR_MODELS":
      return {
        ...state,
        models: [],
      };
    case "SET_PAYMENT_TRANSACTIONS":
      return {
        ...state,
        payment_transactions: [
          ...new Set([...state.payment_transactions, ...action.payload]),
        ],
        filteredItems: [
          ...new Set([...state.payment_transactions, ...action.payload]),
        ],
      };
    case "UPDATE_PAYMENT_TRANSACTION":
      const pt_index = state.filteredItems.findIndex(
        (pt) => pt.id === action.payload.id,
      );
      let pt_updated = [...state.filteredItems];
      pt_updated[pt_index] = action.payload;

      return {
        ...state,
        payment_transactions: pt_updated,
        filteredItems: pt_updated,
      };
    case "REMOVE_PAYMENT_TRANSACTION":
      return {
        ...state,
        payment_transactions: state.payment_transactions.filter(
          (pt) => pt.id !== action.payload,
        ),
        filteredItems: state.payment_transactions.filter(
          (pt) => pt.id !== action.payload,
        ),
      };
    case "CLEAR_PAYMENT_TRANSACTIONS":
      return {
        ...state,
        payment_transactions: [],
      };
    case "SET_TABLES":
      return {
        ...state,
        tables: [...new Set([...state.tables, ...action.payload])],
        filteredItems: [...new Set([...state.tables, ...action.payload])],
      };
    case "UPDATE_TABLE":
      const table_index = state.filteredItems.findIndex(
        (table) => table.id === action.payload.id,
      );
      let tables_updated = [...state.filteredItems];
      tables_updated[table_index] = action.payload;

      return {
        ...state,
        tables: tables_updated,
        filteredItems: tables_updated,
      };
    case "REMOVE_TABLE":
      return {
        ...state,
        tables: state.tables.filter((table) => table.id !== action.payload),
        filteredItems: state.tables.filter(
          (table) => table.id !== action.payload,
        ),
      };
    case "CLEAR_TABLES":
      return {
        ...state,
        tables: [],
      };
    case "SET_NEXT_PAGE":
      return {
        ...state,
        nextPage: action.payload,
      };
    case "SET_HAS_MORE":
      return {
        ...state,
        hasMore: action.payload,
      };
    case "SET_FILTERED_ITEMS":
      return {
        ...state,
        filteredItems: action.payload,
      };
    case "SET_SELECTED_ENTITY":
      return {
        ...state,
        selectedEntity: action.payload,
      };
    case "SET_ENTITY":
      return {
        ...state,
        entity: action.payload,
      };
    case "SET_PAYMENT_ID":
      return {
        ...state,
        paymentId: action.payload,
      };
    case "SET_LOGGED_USER":
      return {
        ...state,
        loggedUser: action.payload,
      };
    case "SHOW_LOADING_OVERLAY":
      return {
        ...state,
        showLoadingOverlay: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
